import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@Environments';
import { GoogleAnalyticsService } from '@App/common/services/google-analytics.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-bootstrap',
  template: '<router-outlet></router-outlet>'
})
export class BootstrapComponent implements OnInit {
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // Initialize Google Analytics
    if (environment?.title === 'Pitch59' && environment?.production) {
      this.googleAnalyticsService.initialize();
    } else {
      const metaTag = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaTag, 'name', 'robots');
      this.renderer.setAttribute(metaTag, 'content', 'noindex, nofollow');
      this.renderer.appendChild(this.document.head, metaTag);
    }
  }
}
